export const baseUrl = "https://centroabapalloncinoblu.it";
export const collection = "/admin/api/collections/get/";
export const singleton = "/admin/api/singletons/get/";

export default {
    // collections
    team: baseUrl + collection + "Team",
    services: baseUrl + collection + "Service",
    contacts: baseUrl + collection + "Contact",

    // singleton
    what: baseUrl + singleton + "What",
    where: baseUrl + singleton + "Where",
    statuto: baseUrl + singleton + "Statuto",
}

export const getImagePath = (image) => {
    return baseUrl + image;
}