<template>
  <div class="page team overflow-x-scroll xl:overflow-x-hidden" id="who">
    <div class="title absolute left-0 right-0 lg:relative">Chi Siamo</div>

    <div id="member-container" class="pb-8 mb-8 xl:mb-0 lg:flex">
      <member
        v-for="(member, index) in $store.state.members"
        :key="index"
        :member="member" />
    </div>
    <div class="flex lg:hidden justify-center align-middle">
      <div
        v-for="(member, index) in $store.state.members"
        :key="`current-${index}`"
        class="slider-index"
        :class="index == currentIndex ? 'font-bold current' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
import member from "@/components/Member.vue";

export default {
  components: {
    member,
  },
  data() {
    return {
      scrollStatus: 0,
    };
  },
  mounted() {
    document
      .getElementById("member-container")
      .addEventListener("scroll", this.handleScroll);
  },
  computed: {
    currentIndex() {
      return Math.floor(this.$data.scrollStatus / document.getElementById("member-container").clientWidth);
    },
  },
  methods: {
    handleScroll(e) {
      this.$data.scrollStatus = e.target.scrollLeft;
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 1024px) {
  #member-container {
    margin-top: 110px;
    scroll-snap-type: x mandatory;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
  }
}

.slider-index {
    width: 15px;
    height: 15px;
    border-color: var(--main);
    background-color: white;
    border-radius: 15px;
    border-width: 2px;
    margin: 0px 5px;
    &.current {
        background-color: var(--main);
    }
}
</style>