<template>
  <div class="service text-center text-md">
    <div class="service-icon m-auto">
        <img class="p-2 lighten" :src="imagePath()" alt="">
    </div>
    <div class="mb-1 mt-4">
      {{ service.name }}
    </div>
  </div>
</template>

<script>
import { getImagePath } from "@/endpoints.js";

export default {
  props: {
    service: {
      name: String,
      icon: {
        path: String,
      },
    },
  },
  methods: {
    imagePath() {
      return getImagePath(this.service.icon.path);
    },
  },
};
</script>

<style>
.service-icon {
    width: 70px;
    height: 70px;
}
</style>