import TeamPage from "@/components/Team.vue";
import WhatPage from "@/components/What.vue";
import ContactsPage from "@/components/Contacts.vue";
import StatutoPage from "@/components/Statuto.vue";
import WherePage from "@/components/Where.vue";

export const Team = TeamPage;
export const What = WhatPage;
export const Contacts = ContactsPage;
export const Statuto = StatutoPage;
export const Where = WherePage;
