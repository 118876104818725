<template>
    <div class="flex flex-1 justify-center logo">
        <img src="@/assets/logo.png" />
    </div>
</template>

<script>
export default {
    name: "Logo",
};
</script>

<style>
.logo img {
    width: 350px;
    height: 350px;
    max-width: 350px;
    max-height: 350px;
}
</style>