<template>
  <div class="contact">
    <a :href="contact.url">
      <img class="p-2 lighten" :src="imagePath()" alt="" />
    </a>
  </div>
</template>

<script>
import { getImagePath } from "@/endpoints.js";

export default {
  props: {
    contact: {
      name: String,
      url: String,
      icon: {
        path: String,
      },
    },
  },
  methods: {
    imagePath() {
      return getImagePath(this.contact.icon.path);
    },
  },
};
</script>

<style lang="scss">
.contact {
  width: 80px;
  img {
    transition: 150ms;
    &:hover {
      padding: 0 !important;
      transform: rotate(5deg);
    }
  }
}
</style>