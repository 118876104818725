<template>
  <div class="page team" id="what">
    <div class="title">Cosa facciamo</div>
    <div class="flex justify-between" v-html="sanitized"></div>
    <div class="grid grid-cols-1 xl:grid-cols-3 gap-8 my-8">
        <service v-for="(service, index) in $store.state.services" :key="`service-${index}`" :service="service"></service>
    </div>
  </div>
</template>

<script>
import Service from "@/components/Service.vue";

export default {
  components: {
    Service,
  },
  computed: {
    sanitized() {
      return this.$store.state.what.description?.replace(/\n/g, "<br />");
    },
  },
};
</script>

<style>
</style>