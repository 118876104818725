<template>
  <div class="page team" id="statuto">
    <div class="title">Statuto</div>
    <div v-html="sanitized"></div>
  </div>
</template>

<script>

export default {
  computed: {
    sanitized() {
      return this.$store.state.statuto.description;
    },
  },
};
</script>

<style>
</style>