<template>
    <div class="home">
        <Menu />
        <div class="hero flex p-8 xl:p-0 justify-center align-middle items-center">
            <Logo class="flex-1 flex" />
        </div>        
        <section>
            <team></team>
        </section>
        <section>
            <where></where>
        </section>
        <section>
            <what></what>
        </section>
        <section>
            <contacts></contacts>
        </section>
        <section>
            <statuto></statuto>
        </section>
    </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import Menu from "@/components/Menu.vue";
import endpoint from "@/endpoints.js";
import { Team, What, Contacts, Statuto, Where } from "@/modules/pages.js";

export default {
    name: "Home",
    components: {
        Logo,
        Menu,
        Team,
        What,
        Where,
        Statuto,
        Contacts
    },
    
    async mounted() {
        await this.setup();
    },

    methods: {
        async setup() {
            fetch(endpoint.team).then(async r => this.$store.state.members = (await r.json()).entries);
            fetch(endpoint.services).then(async r => this.$store.state.services = (await r.json()).entries);
            fetch(endpoint.contacts).then(async r => this.$store.state.contacts = (await r.json()).entries);
            fetch(endpoint.where).then(async r => this.$store.state.where = (await r.json()));
            fetch(endpoint.statuto).then(async r => this.$store.state.statuto = (await r.json()));
            fetch(endpoint.what).then(async r => this.$store.state.what = (await r.json()));
        },
    },
};
</script>

<style scoped>
.hero {
    height: 100vh
}
.home {
    padding-bottom: 200px;
}
section:nth-child(even) {
    background: #f3feff;
}
</style>
