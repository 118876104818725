<template>
  <div class="contacts page" id="contacts">
      <div class="title">Contatti</div>
      <div class="contacts flex justify-center align-center">
          <contact :contact="contact" v-for="(contact, index) in $store.state.contacts" :key="`contact-${index}`"></contact>
      </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";

export default {
  components: {
    Contact,
  },
}
</script>

<style>

</style>