<template>
    <div id="app">
        <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
        <!-- <div v-show="!validPassword" class="pwd-container">
            <div>
                <div>Pwd</div>
                <input type="text" v-model="password" />
            </div>
        </div> -->

        <router-view />
    </div>
</template>

<script>
import md5 from "md5";

export default {
    computed: {
        validPassword() {
            return md5(this.$data.password) == this.$data.encodedPassword;
        },
    },
    data() {
        return {
            password: "",
            encodedPassword: "b5619fc57f0c4819ced12a8496e8587d",
            fadeInElements: [],
        };
    },
    mounted() {
        this.$data.fadeInElements = Array.from(
            document.getElementsByClassName("page")
        );
        document.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    },
    methods: {
        handleScroll() {
            for (var i = 0; i < this.$data.fadeInElements.length; i++) {
                var elem = this.$data.fadeInElements[i];
                if (this.isElemVisible(elem)) {
                    elem.classList.add("shown");
                    this.$data.fadeInElements.splice(i, 1); // only allow it to run once
                }
            }
        },

        isElemVisible(el) {
            var rect = el.getBoundingClientRect();
            var elemTop = rect.top + 200; // 200 = buffer
            var elemBottom = rect.bottom;
            return elemTop < window.innerHeight && elemBottom >= 0;
        },
    },
};
</script>

<style lang="scss">
#app {
    font-family: "HelvetiHand", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #434343;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.pwd-container {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;

    input {
        height: 30px;
        width: 250px;
        padding: 4px;
        border: solid 1px #dadada;
    }
}

body,
html {
    margin: 0;
    padding: 0;
}
</style>
