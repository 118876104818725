<template>
  <div class="member lg:flex-1 text-center lg:mx-4" v-if="member">
    <div
      class="avatar bg-bottom bg-no-repeat bg-contain border-b-4"
      :style="{ backgroundImage: `url(${imagePath()}` }"
    ></div>
    <div class="font-bold mt-4">{{ member.name }}</div>
    <div class="font-bold mb-4">{{ member.surname }}</div>
    <div>{{ member.role }}</div>
  </div>
</template>

<script>
import { getImagePath } from "@/endpoints.js";

export default {
  props: {
    member: {
      name: String,
      surname: String,
      role: String,
      description: String,
      avatar: {
        path: String,
      },
    },
  },
  methods: {
    imagePath() {
      return getImagePath(this.member.avatar.path);
    },
  },
};
</script>

<style>
.avatar {
  height: 25vw;
  max-height: 250px;
  border-color: var(--main);
}
@media (max-width: 1024px) {
    .avatar {
    height: 50vh;
    max-height: 50vh;
    border-color: var(--main);
  }
  .member {
    margin: 0 10vw;
    min-width: 80vw;
    scroll-snap-align: center;
  }
}
</style>