import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// initial state
const state = () => ({
    members: [],
    what: {},
    statuto: {},
    where: {},
    services: {},
    contacts: {},
    count: 0,
    menuOpen: false,
})

export default {
    namespaced: true,
    state,
}