<template>
  <div>
    <div
      @click="show = !show"
      class="toggle-button fixed xl:hidden right-10 top-10 z-20"
    >
      <div class="menu-button" >
        <template v-if="show">
          <span class="menu-element hide-menu "></span>
          <span class="menu-element hide-menu"></span>
        </template>
        <template v-else>
          <span class="menu-element show-menu"></span>
          <span class="menu-element show-menu"></span>
          <span class="menu-element show-menu"></span>
        </template>
      </div>
    </div>
    <div
      class="
        menu
        xl:flex
        flex-col
        xl:flex-row
        flex
        fixed
        h-full
        xl:h-auto
        justify-center
        align-middle
        xl:pt-4
        bg-white
        w-full
        z-10
      "
      :class="!show ? 'left-full opacity-0 xl:left-0 xl:opacity-100' : 'left-0 opacity-100'"
    >
      <div
        v-for="(item, index) in menu"
        :class="{ wip: item.wip }"
        class="text-right z-20 item overflow-visible whitespace-nowrap mx-8 xl:mx-4"
        :key="index"
        @click="goToPath(item.path)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      menu: [
        { wip: false, label: "Chi siamo", path: "who" },
        { wip: false, label: "Dove siamo", path: "where" },
        { wip: false, label: "Cosa facciamo", path: "what" },
        { wip: false, label: "Contattaci", path: "contacts" },
        { wip: false, label: "Statuto", path: "statuto" },
        { wip: false, label: "Foto", path: null }, // WIP
      ],
    };
  },

  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    goToPath(path) {
      this.show = false;
      const el = document.getElementById(path);
      if (el) el.scrollIntoView({ behavior: "smooth" });
    },
    handleScroll() {},
  },
};
</script>

<style lang="scss" scoped>
.menu {
  line-height: 3.3em;
  letter-spacing: 2px;
  transition: 250ms;

  .item.wip:hover::after {
    content: "wip";
    padding: 3px 5px;
    border-radius: 3px;
    background: #ff9800;
    color: white;
    font-size: 0.8em;
  }

  .item.wip:active::after {
    content: "";
  }

  .item {
    cursor: pointer;
    transition: 200ms;
  }

  .item:hover {
    transform: rotate(-0.3deg);
    transition: 65ms;
  }

  .item::first-letter {
    color: var(--main);
  }
}

.menu-button {
  transition: 250ms;
  height: 3px;
  width: 25px;

  .menu-element {
    width: 0px;

    &:nth-child(1) {
      animation-delay: 0ms;
    }
    &:nth-child(2) {
      animation-delay: 75ms;
    }
    &:nth-child(3) {
      animation-delay: 150ms;
    }
  }

  .show-menu {
    height: 2px;
    background: #191919;
    margin-bottom: 7px;
    display: block;
    animation: drawLine cubic-bezier(0.445, 0.05, 0.55, 0.95) 250ms forwards;
    transform: translateY(-5px);
  }

  .hide-menu {
    height: 2px;
    background: #191919;
    display: block;
    width: 25px;
    opacity: 0;
    animation: fadeLine cubic-bezier(0.445, 0.05, 0.55, 0.95) 150ms forwards;

    &:nth-child(1) {
          transform: translate(0%, 50%) rotate(45deg);
    }
    &:nth-child(2) {
          transform: translate(0%, -50%) rotate(-45deg);
    }
  }
  
  @keyframes drawLine {
    from {
      width: 0px;
    }
    to {
      width: 25px;
    }
  }

  @keyframes fadeLine {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>