<template>
  <div class="where page" id="where">
    <div class="title">Dove siamo</div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.252154170176!2d8.559524851519093!3d40.73447677922801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12dc630878794a0d%3A0x7576e2bbffdaeca3!2sCentro%20ABA%20Palloncino%20Blu!5e0!3m2!1sit!2sit!4v1661769171761!5m2!1sit!2sit"
      :width="width"
      height="600"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>

<script>
export default {
  computed: {
    width() {
      return document.getElementById("where") ? document.getElementById("where").offsetWidth : 1024;
    },
  },
};
</script>

<style></style>